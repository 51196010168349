import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/Layout'
import React from 'react'

const ContactComplete = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="inner">
        <div className="common-header">
          <h1>お問い合わせ</h1>
          <p className="subtitle">Contact</p>
        </div>
        {/* /.common-header */}

        <p>
          お問い合わせいただきありがとうございました。
          <br />
          お問い合わせを受け付けました。
          <br />
          <br />
          折り返し、担当者よりご連絡いたしますので、恐れ入りますが、しばらくお待ちください。
        </p>
        <p className="common-margin l"></p>
        <p className="common-margin l"></p>
      </div>
      {/* /.inner */}
    </Layout>
  )
}

export default ContactComplete
